.page-title {
  &.orders {
    margin-bottom: 10px;
    &>a {
      display: inline-block;
      margin-right: 15px;
      padding: 14px 20px;
      background-color: transparent;
      border: none;
      color: #636363;
      transition: all .3s;

      &:first-child {
        background-color: #fff;
        color: #4762E6;
        box-shadow: 2px 2px 10px rgba(118, 118, 118, 0.2);
        border-radius: 10px;
      }
    }
  }
}

.section__orders {
  & .section__header {
    display: flex;

    & .title {
      padding: 0px 25px 0px 10px;
      font-size: 20px;
      color: #636363;
      border-right: 2px solid #d9d9d9;
    }

    & .filter select {
      min-width: 110px;
    }
  }

  & .section__content {
    & .table tbody tr,
    & .table thead tr {
      & th:first-child {
        text-indent: 30px;
      }
      & th,
      & td {
        &:first-child {
          width: 250px !important;
          text-align: left;
        }
      }
    }

    & .table tbody tr {
      background-color: #fff;
      td:first-child span:first-child {
        width: 12px;
      }
      td:first-child span.dot {
        height: 12px;
        background-color: #4762E6;
        border-radius: 50%;
        display: inline-block;
      }

      td:nth-child(2) {
        border-left: none;
      }

      &.status-ordered {
        background-color: #f7f7f7;
      }

      & .status-cell {
        & span {
          background-color: #f2f2f2;
          padding: 6px 8px;
          border-radius: 6px;
        }

        & span.status-canceled {
          color: #ed4c5c;
          background-color: #fdedef;
        }

        & img {
          margin-right: 5px;
          margin-bottom: 3px;
        }
      }
      & td.rescheduled {
        & button {
          padding: 3px 6px;
          cursor: pointer;
        }
      }
    }
  }
}

.ant-popover-inner-content {
  & img[alt='details'] {
    margin-top: 4px;
  }
}

// UPDATE MODAL

.order-update-modal {
  height: 500px;
  & .content {
    height: auto;
  }
  & .ant-form{
    & .ant-radio-group {
      margin-left: 90px;
      & label.ant-radio-wrapper {
        font-size: 18px;
        & .ant-input.cancel-reason {
          width: 100%;
          margin-left: -25px;
          margin-top: 10px;
          height: 40px;
          font-size: 16px;
          border: 1px solid red;
        }
      }
    }
    & .form-group {
      position: absolute;
      bottom: 30px;
      left: 77px;
    }
  }
}

// RESCHEDULED MODAL 
.order-rescheduled-modal {
  height: 350px;
  & .content {
    height: 300px;
    justify-content: space-between;
    & > form {
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & input {
        font-size: 20px;
      }
    }
  }
}