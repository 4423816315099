.input-blog-page {
  border-radius: 15px;
  font-size: 18px;
  margin: 10px 0px 10px 0px;
}

.tiny-blog-page .tox {
  border-radius: 20px;
  margin: 10px 0px 10px 0px;
}

.title-blog {
  font-size: 18px;
  margin: 0 0px 10px 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(0, 116, 218);
}

.title-container {
  padding: 5px;
  width: 100%;
}
