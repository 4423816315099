body,
#root,
.app {
  min-height: 100vh;
}

html {
  &.overflow-hidden {
    overflow-y: hidden;
  }
}

body {
  color: $white;
  font-size: $fontBase;
  line-height: $defaultRatio;
  font-family: $fontOpenSans;
  background-color: $light15;
  text-size-adjust: 100%;
}

.relative {
  position: relative;
}

.min-h-table-content,
.min-h-account-content {
  min-height: calc(100% - #{$sectionHeaderHeight});
}

::selection {
  background-color: $light15;
  color: $dark80;
}

// badge
.badge {
  position: relative;
  height: 100%;
  padding: 5px 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  height: 36px;

  &::before {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    content: '';
    clear: both;
    display: block;
    margin-right: 10px;
  }

  &.active {
    color: rgb(0, 168, 160) !important;
    background-color: rgba(0, 168, 160, 0.1);

    &::before {
      background-color: rgb(0, 168, 160);
    }
  }

  &.inactive {
    color: rgb(237, 74, 90) !important;
    background-color: rgba(237, 74, 90, 0.1);

    &::before {
      background-color: rgb(237, 74, 90);
    }
  }
}

.badge-single {
  justify-content: center;

  &::before {
    content: inherit;
  }

  &-fsa {
    background-color: #ccebea;
    color: #009d94;
  }

  &-otc {
    background-color: #00cecb;
    color: #fff;
  }

  &-wic {
    background-color: #1967d2;
    color: #ffdd00;
  }

  &-ebt {
    background-color: #c2caf2;
    color: #1967d2;
  }
}

.badge-area {
  display: grid;
  grid-template-columns: repeat(auto-fill, 55px);
  grid-gap: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* UPLOAD AVATAR IMAGE */
.avatar-image {
  width: 100%;
  display: block;
}

.avatar-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px dashed #ccc;
  border-radius: 6px;
  overflow: hidden;

  & .input-upload-wrapper,
  & .image-upload-wrapper {
    width: 100%;
    height: 100%;
  }

  & .input-upload-wrapper {
    & .label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .image-upload-wrapper {
    & .img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .removed-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;

      & .btn-delete {
        width: 20px;
        height: 20px;
        background-color: black;
      }
    }

    &:hover {
      & .removed-overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.input {
  &-error {
    border-color: red !important;

    &:hover {
      border-color: red !important;
    }
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.img-responsive {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.image {
  overflow: hidden;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.c-red {
  color: $red40;
}

.relative-error {
  position: relative !important;
  top: 10px !important;
  white-space: nowrap;
  font-size: 14px;
}

.attributesSelect span {
  margin-top: -13px;
}

.ant-notification {
  z-index: 9999 !important; /* assuming 2000 is the z-index of the Drawer */
}

input.placeholder-style {
  color: #a9a9a9; /* Placeholder text color, like light gray */
  border: 1px solid #a9a9a9; /* A light gray border */
  background-color: #ffffff; /* White background */
  font-style: italic; /* If placeholder text is typically italic */
  padding: 8px 12px; /* Add some padding for comfort */
  font-size: 16px; /* Adjust according to preference */
  border-radius: 4px; /* If you want rounded corners */
}

input.placeholder-style::placeholder {
  color: #a9a9a9; /* This will color the actual placeholder text */
  font-style: italic; /* If you want the actual placeholder text to be italic */
}

input.placeholder-style:focus {
  outline: none;
  border-color: #007bff; /* A different border color when the input is focused, like a blue color. Adjust accordingly. */
}
